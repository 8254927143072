.button {
	// common styles
	text-transform: uppercase;
	font-weight: bold;
	position: relative;
	padding: 20px;
	border-radius: 5px;
	font-size: 20px;
	margin-top: 20px;
	border: 0;
	cursor: pointer;
	&.disabled {
		cursor: not-allowed;
	}
	// styles for day mode
	.day & {
		box-shadow: inset 0 0 0 4px black;
		background-color: transparent;
		&:before {
			z-index: -1;
			content: "";
			display: block;
			position: absolute;
			left: -7px;
			top: 7px;
			right: 7px;
			bottom: -7px;
			background-color: cyan;
			border-radius: 5px;
		}
		&.hover {
			color: white;
			&:before {
				background-color: magenta;
			}
		}
		&.mousedown {
			&:before {
				background-color: darkmagenta;
			}
		}
	}

	// styles for night mode
	.night & {
		background-color: transparent;
		box-shadow: inset 0 0 0 3px pink, 0 0 10px 4px rgba(magenta, 0.8),
			inset 0 0 10px 4px rgba(magenta, 0.8);
		color: white;
		&.hover {
			color: lime;
			box-shadow: inset 0 0 0 3px lime, 0 0 10px 4px rgba(green, 0.8),
				inset 0 0 10px 4px rgba(green, 0.8);
		}
		&.mousedown {
			background-color: darkgreen;
		}
		&.disabled {
			opacity: 0.66;
			box-shadow: inset 0 0 0 3px darkmagenta;
		}
	}
}
