.input {
	&,
	&:focus {
		outline: none;
	}
	border: 0;
	padding: 2px 4px;
	.day & {
		border: solid 1px gray;
		color: black;
		&.focus {
			box-shadow: 0 0 4px 4px #00bfff;
		}
	}
	.night & {
		border: solid 1px white;
		color: white;
		&.focus {
			box-shadow: 0 0 4px 4px orange;
		}
	}
	.selected-text {
		background-color: #00bfff;
	}
}

@keyframes blink {
	50% {
		opacity: 0;
	}
}

.caret {
	width: 1px;
	height: 16px;
	margin-top: 2px;
	background-color: magenta;
	position: absolute;
	animation: blink 1s step-start infinite;
}
