.background {
	position: fixed;
	overflow: scroll;
	bottom: 0;
	&,
	& > * {
		top: 0;
		right: 0;
		left: 0;
		min-height: 100vh;
	}
	& > .night {
		background-color: black;
		color: white;
	}
	& > .day {
		background-color: white;
		color: black;
		position: absolute;
	}
}

h1 {
	margin: 0;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	padding-bottom: 2rem;
	.header {
		font-weight: 100;
		font-size: 2.5rem;
		background-image: linear-gradient(90deg, magenta, pink);
		background-clip: text;
		color: transparent;
		display: inline-block;
	}
	.subheader {
		font-family: "JetBrains Mono", monospace;
		font-size: 1.25rem;
	}
}

.back-button {
	padding: 5px 0 0 5px;
	position: fixed;
}

@media only screen and (max-width: 768px) {
	#background {
		clip-path: none !important;
	}
	.day {
		opacity: 0;
	}
	hr {
		display: none;
	}
	.back-button {
		margin-left: 0;
		top: 8px;
		z-index: 1;
		button {
			height: 16px;
			padding: 8px 24px 30px 24px;
			margin-top: 5px;
		}
	}
}

@media only screen and (min-width: 769px) {
	.background {
		& > .night,
		& > .day {
			padding: 1rem 2rem 2rem 2rem;
		}
	}

	#background {
		clip-path: circle(100px at 0 0);
	}

	.back-button {
		margin-left: -100px;
		top: 42px;
		height: 140px;
		width: 150px;
	}

	.project-page p::before {
		.day & {
			background-color: lime;
		}
		.night & {
			background-color: magenta;
		}
	}

	hr {
		width: 33%;
		margin: 4rem auto 3rem;
	}
}
