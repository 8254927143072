@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;600&family=JetBrains+Mono&display=swap");

$maxWidth: 80rem;

* {
  font-family: "Encode Sans", sans-serif;
}

h2 {
  font-size: 2.75rem;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 2px;
}
h3 {
  font-weight: 600;
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.75em;
  text-transform: uppercase;
  letter-spacing: 1px;
}
p {
  font-size: 1.1rem;
  line-height: 1.75em;
  position: relative;
  font-weight: 200;
  letter-spacing: 1px;
}
label {
  font-family: "Jetbrains Mono";
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  padding: 0.5em 0;
}

.content-wrapper {
  max-width: $maxWidth;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .content-wrapper {
    max-width: $maxWidth;
    margin: 0 2rem;
    padding-top: 1rem;
  }
  p {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 769px) {
  .content-wrapper {
    max-width: $maxWidth;
    margin: 0 auto;
  }
  p {
    margin-bottom: 2.5rem;
  }
}
