@media only screen and (max-width: 768px) {
	.project-page {
		h2 {
			margin-top: 4rem;
		}
	}
}

@media only screen and (min-width: 769px) {
	.project-page {
		margin: 0 auto;
		padding: 20px 100px;
		max-width: calc(60rem * 1.2);
		position: relative;
		h3 {
			padding-right: 1ch;
			display: inline-block;
			&::after {
				content: ":";
			}
		}
		p {
			position: relative;
			&::before {
				height: 1.4rem;
				width: 2px;
				content: "";
				position: absolute;
				left: -0.75rem;
				top: 0.3rem;
				display: block;
			}
		}
	}
}
