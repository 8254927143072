.textarea {
	&,
	&:focus {
		outline: none;
	}
	border: 0;
	padding: 2px 4px;
	&.focus {
		box-shadow: 0 0 4px 4px #00bfff;
	}
	.day & {
		border: solid 1px gray;
		color: black;
	}
	.night & {
		border: solid 1px white;
		color: white;
	}
}
