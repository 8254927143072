.horizontal-group {
	display: inline-flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	& > * {
		margin: 0;
	}
}

.rac-image-group {
	& > figure:nth-child(1) {
		width: 386%;
	}
}
