@media only screen and (max-width: 768px) {
	.contact-form-title {
		font-size: 1.5rem;
		br {
			display: none;
		}
	}
}

@media only screen and (min-width: 769px) {
	.contact-form {
		margin: 0 auto;
		display: flex;
		max-width: 50rem;
	}

	.contact-form-title {
		flex-grow: 0;
		padding: 0 5rem 0 0;
	}

	.contact-form-body {
		flex-grow: 1;
		.button {
			margin-top: 1.5rem;
		}
	}

	.form-group {
		& + & {
			margin-top: 1em;
		}
	}
}
