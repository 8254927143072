.project-list {
	padding: 0;
	& > li {
		list-style: none;
	}
}

@media only screen and (max-width: 768px) {
	.project-preview-image {
		width: 100%;
		height: 100%;
	}
	a {
		display: block;
		text-align: right;
	}
	li {
		border-bottom: solid 1px silver;
		padding-bottom: 2rem;
		margin-bottom: 2rem;
		img {
			padding-bottom: 1rem;
		}
	}
}

@media only screen and (min-width: 769px) {
	.project-list {
		margin-bottom: 2.5rem;
		margin: 0 auto;
		& > li {
			display: flex;
			&:not(:last-child) {
				margin-bottom: 4rem;
			}
			img {
				padding-right: 2.5rem;
			}
			padding: 2rem;
			border-width: 1px;
			border-style: solid;
			&:nth-child(even) {
				border-image: linear-gradient(to left, silver, rgba(0, 0, 0, 0)) 1;
			}
			&:nth-child(odd) {
				border-image: linear-gradient(to right, silver, rgba(0, 0, 0, 0)) 1;
			}
		}
	}

	.project-preview-image {
		width: 300px;
		height: 100%;
	}
}
