.img {
	img {
		width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	.img {
		margin: 0;
		padding-bottom: 2rem;
	}
	.project-page {
		img {
			padding-top: 3rem;
		}
	}
	blockquote {
		margin: 0;
	}
}

@media only screen and (min-width: 769px) {
	.img {
		display: table;
		position: relative;
		display: table;
		margin: 0 auto 2.5rem;
		blockquote {
			margin: 0 0 1.5em;
			&::before {
				content: open-quote;
			}
			&::after {
				content: close-quote;
			}
		}
	}
	.img-position-bottom {
		.caption-group {
			display: table-caption;
			caption-side: bottom;
		}
	}

	.img-position-right {
		display: flex;
		flex-direction: row;
		align-items: center;
		img {
			width: calc(50%);
		}
		.caption-group {
			padding-left: 2.5rem;
		}
	}
}
